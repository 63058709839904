@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'Centra No2';
  src: url('assets/fonts/Centra-No2-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('assets/fonts/Centra-No2-Normal.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('assets/fonts/Centra-No2-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('assets/fonts/Centra-No2-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Centra No2';
  src: url('assets/fonts/Centra-No2-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

body {
  font-family: "Centra No2";
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #163317;
  border-radius: 8px;
}

/* Swiper customization */
.centered .swiper-pagination-bullet-active {
  background: #163317 !important;
}

.centered .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background: #D1C7B8 !important;
  opacity: 1 !important;
}

.centered .swiper-slide:not(.swiper-slide-active) {
  transform: scaleY(0.8) !important;
  transition: transform ease-in-out 0.3s;
}

.swiper-slide {
  transition: transform ease-in-out 0.3s;
  height: auto !important;
}

.container {
  max-width: 1440px;
  margin: auto;
}

@media (min-width: 1440px) {
  .left-container {
    margin-left: auto;
    margin-right: 0;
    padding-right: 0;
    padding-left: 60px;
    max-width: calc(100vw - (100vw - 1440px) / 2);
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .left-container {
    margin-left: auto;
    margin-right: 0;
    padding-right: 0;
    padding-left: 60px;
    max-width: 100%;
  }
}



@media (min-width: 1440px) {
  .oneline-carousel .swiper-slide:last-child {
    margin-right: calc((100vw - 1440px) / 2 + 60px);
  }

  .oneline-carousel .swiper-slide:first-child {
    margin-left: calc((100vw - 1440px) / 2 + 60px)
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .oneline-carousel .swiper-slide:last-child {
    margin-right: 60px;
  }

  .oneline-carousel .swiper-slide:first-child {
    margin-left: 60px
  }
}

@media (min-width: 1440px) {
  .oneline-carousel-both .swiper-slide:last-child {
    margin-right: calc((100vw - 1440px) / 2 + 60px);
  }

  .oneline-carousel-both .swiper-slide:first-child {
    margin-left: calc((100vw - 1440px) / 2 + 60px)
  }
}

@media (max-width: 768px) {
  .oneline-carousel-both .swiper-slide:last-child {
    margin-right: 16px;
  }

  .oneline-carousel-both .swiper-slide:first-child {
    margin-left: 16px
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .oneline-carousel-both .swiper-slide:last-child {
    margin-right: 60px;
  }

  .oneline-carousel-both .swiper-slide:first-child {
    margin-left: 60px
  }
}

@media (min-width: 768px) {
  .review {
    height: 550px;
  }
}

.word-container {
  display: flex;
}

.word {
  margin-right: 20px;
  position: relative;
}

.underline-animation {
  background-repeat: no-repeat;
  background-size: 0% 100%;
  animation: underlineAnimation 1.5s ease-in-out forwards;
}

.chill-mood {
  background-image: linear-gradient(180deg, transparent 58%, #4571CB 0);
}

.happy-mood {
  background-image: linear-gradient(180deg, transparent 58%, #C11F50 0);
}

.sleepy-mood {
  background-image: linear-gradient(180deg, transparent 58%, #32437A 0);
}

.relaxing-mood {
  background-image: linear-gradient(180deg, transparent 58%, #4571CB 0);
}

.energy-mood {
  background-image: linear-gradient(180deg, transparent 58%, #634496 0);
}

.blur-effect {
  top: -3px;
  filter: url(#wherearemyglasses);
}

@keyframes underlineAnimation {
  to {
    background-size: 100% 100%;
  }
}